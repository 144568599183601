<template>
  <div class="page__industrial">
    <el-button @click="add" type="primary">添加工业设计</el-button>
    <y-table
      style="margin-top: 22px"
      ref="listPageRef"
      :columns="columns"
      :fetch="fetchFn"
      :pagination="false"
    ></y-table>
  </div>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      model: {
        keyword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '关键词',
            prop: 'keyword'
          }
        }
      ],
      columns: [
        {
          label: '图片',
          prop: 'paramValue',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return <el-avatar shape="square" size={'large'} src={v}></el-avatar>
          }
        },

        {
          label: '创建时间',
          prop: 'createTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '修改时间',
          prop: 'updateTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '操作',
          render: (h, record) => {
            return (
              <div>
                <el-button vOn:click={() => this.toModify(record)} type="text">
                  修改
                </el-button>
                <el-button
                  vOn:click={() => this.deleteAction(record)}
                  type="text"
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ]
    }
  },
  methods: {
    fetchFn(data) {
      return this.$serve
        .baseparamList({
          data: {
            ...data,
            paramId: 1001
          }
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return {
            records: res
          }
        })
    },
    add() {
      this.$router.push('/industrial/add')
    },
    toModify(record) {
      this.$router.push(`/industrial/update/${record.id}`)
    },
    deleteAction(record) {
      this.$confirm('确认删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(record)
        })
        .catch(() => {})
    },
    async delete(record) {
      const res = await this.$serve.baseparamDelete({
        aimid: record.id
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('删除成功')
      this.$refs.listPageRef.fetchData()
    }
  }
}
</script>
